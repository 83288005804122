import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../components/layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import ContactForm from "../../components/contactForm";
import { BgImage } from "gbimage-bridge";
import CardFacialNew from "../../components/facial-new-card";
import { checkPropertiesForNull } from "../../../utils";
import { SafeHtmlParser } from "../../components/safeHtmlParser";
import uuid4 from "uuid4";

const FacialAestheticsTreatmentsPage = () => {
  const data = useStaticQuery(graphql`
    query {
      reviews {
        reviewCount
      }
      schemaLogo: wpMediaItem(title: { eq: "dark-logo" }) {
        altText
        localFile {
          publicURL
          childImageSharp {
            original {
              height
              width
            }
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: TRACED_SVG
            )
          }
        }
      }
      site {
        siteMetadata {
          siteUrl
        }
      }
      pageData: wpPage(slug: { eq: "facial-aesthetics-treatments" }) {
        slug
        seoFieldGroups {
          metaTitle
          metaDescription
          localBusinessSchema
          openGraphDescription
          openGraphTitle
          productSchema
          image {
            node {
              altText
              publicUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                  original {
                    width
                    height
                  }
                }
              }
            }
          }
        }
        facialAestheticsPageFieldGroups {
          facialAestheticsIntroSection {
            description
            heading
            subHeading
            treatments {
              description
              findOutButtonText
              selectorId
              imagePosition
              heading
              overlayDescription
              overlayHeading
              overlaySubHeading
              image {
                node {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP]
                        quality: 100
                        transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                        layout: CONSTRAINED
                        placeholder: BLURRED
                      )
                      original {
                        width
                        height
                      }
                    }
                  }
                }
              }
              overlayCta {
                target
                title
                url
              }
            }
          }
          facialAestheticsContactSection {
            heading
            subHeading
            content
            formButtonLabel
          }
          facialAestheticsAboveFooterImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                  original {
                    width
                    height
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const {
    pageData: { seoFieldGroups, facialAestheticsPageFieldGroups, slug },
  } = data;

  const {
    facialAestheticsIntroSection,
    facialAestheticsContactSection,
    facialAestheticsAboveFooterImage,
  } = facialAestheticsPageFieldGroups;

  const siteUrl = data.site.siteMetadata.siteUrl;
  const serviceSchema = {
    "@context": "https://schema.org/",
    "@type": "Product",
    "@id":
      `https://www.dentalhouseexeter.co.uk/treatments/${slug}/#${slug}`,
    image: `https://www.dentalhouseexeter.co.uk${seoFieldGroups?.image?.node.localFile.publicURL}`,
    mpn: `https://www.dentalhouseexeter.co.uk/treatments/${slug}`,
    sku: `https://www.dentalhouseexeter.co.uk/treatments/${slug}`,
    description: `${seoFieldGroups?.metaDescription}`,
    logo: `https://www.dentalhouseexeter.co.uk${data.schemaLogo.localFile.publicURL}`,
    name: "Dental House Exeter",
    url: "https://www.dentalhouseexeter.co.uk",
    brand: {
      "@type": "Organization",
      logo: `https://www.dentalhouseexeter.co.uk${data.schemaLogo.localFile.publicURL}`,
      image: `https://www.dentalhouseexeter.co.uk${seoFieldGroups?.image?.node.localFile.publicURL}`,
      name: "Dental House Exeter",
      url: "https://www.dentalhouseexeter.co.uk",
    },
    aggregateRating: {
      "@type": "AggregateRating",
      reviewCount: `${data.reviews.reviewCount}`,
      ratingValue: "5",
      bestRating: "5",
      worstRating: "1",
    },
    review: {
      "@type": "Review",
      url: "https://www.dentalhouseexeter.co.uk/reviews",
      datePublished: "2022-03-21T17:58:29+00:00",
      reviewBody:
        "This is the best dental practice I have known. Not only in terms of expertise but the lovely sociability of every single member of the practice team.",
      author: {
        "@type": "Person",
        name: "Caroline P",
      },
      reviewRating: {
        "@type": "Rating",
        ratingValue: "5",
        bestRating: "5",
        worstRating: "1",
      },
      publisher: {
        "@type": "Organization",
        name: "Dental House Exeter",
        sameAs: "https://www.dentalhouseexeter.co.uk",
      },
    },
  };


  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "Treatments",
        item: {
          url: `${siteUrl}/treatments`,
          id: `${siteUrl}/treatments`,
        },
      },
      {
        "@type": "ListItem",
        position: "3",
        name: "Facial Aesthetics Treatments",
        item: {
          url: `${siteUrl}/treatments/facial-aesthetics-treatments`,
          id: `${siteUrl}/treatments/facial-aesthetics-treatments`,
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(serviceSchema)}
        </script>
      </Helmet>
      <GatsbySeo
        title={seoFieldGroups?.metaTitle}
        description={seoFieldGroups?.metaDescription}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}/treatments/facial-aesthetics-treatments`,
          title: `${seoFieldGroups?.openGraphTitle}`,
          description: `${seoFieldGroups?.openGraphDescription}`,
          images: [
            {
              url: `${seoFieldGroups?.image?.node.localFile.publicURL}`,
              width: `${seoFieldGroups?.image?.node.localFile.childImageSharp.original.width}`,
              height: `${seoFieldGroups?.image?.node.localFile.childImageSharp.original.height}`,
              alt: `${seoFieldGroups?.image?.node.altText}`,
            },
          ],
        }}
      />

      <Layout>
        {facialAestheticsIntroSection &&
          !checkPropertiesForNull(facialAestheticsIntroSection, [
            "heading",
          ]) && (
            <section>
              <Container>
                <Row>
                  <Col className="text-center py-5 py-lg-7 ">
                    <p className="text-text-secondary jost-bold fs-6">
                      {facialAestheticsIntroSection?.subHeading}
                    </p>
                    <h1 className=" display-4 pb-5">
                      {facialAestheticsIntroSection?.heading}
                    </h1>
                    <SafeHtmlParser
                      htmlContent={facialAestheticsIntroSection?.description}
                    />
                  </Col>
                </Row>
              </Container>
            </section>
          )}
        {facialAestheticsIntroSection &&
          facialAestheticsIntroSection.treatments &&
          facialAestheticsIntroSection.treatments.length > 0 && (
            <section className=" pb-lg-7 pb-5">
              <Container>
                <Row className="justify-content-lg-center justify-content-xl-start  g-5">
                  {facialAestheticsIntroSection.treatments.map((cardItem) => (
                    <CardFacialNew
                      key={uuid4()}
                      secondaryHeading={cardItem?.overlayHeading}
                      secondarySubheading={cardItem?.overlaySubHeading}
                      secondaryText={
                        <SafeHtmlParser
                          htmlContent={cardItem?.overlayDescription}
                        />
                      }
                      idAnim={cardItem?.selectorId}
                      objPos={cardItem?.imagePosition || "top center"}
                      span={4}
                      imageHeight="250px"
                      headingHeight="83px"
                      image={
                        cardItem?.image.node?.localFile.childImageSharp
                          .gatsbyImageData
                      }
                      imgAlt={cardItem.image.node?.altText}
                      title={<SafeHtmlParser htmlContent={cardItem?.heading} />}
                      text={
                        <SafeHtmlParser htmlContent={cardItem?.description} />
                      }
                      bgColour="bg-primary"
                      url={cardItem?.overlayCta.url}
                      viewPageBtnText={cardItem?.overlayCta?.title}
                      buttonText={cardItem?.findOutButtonText}
                    />
                  ))}
                </Row>
              </Container>
            </section>
          )}
        {facialAestheticsContactSection &&
          !checkPropertiesForNull(facialAestheticsContactSection, [
            "heading",
          ]) && (
            <section className="py-5 py-lg-7">
              <Container>
                <Row>
                  <Col lg={6} className="text-center text-lg-start">
                    <h2 className="text-accent jost-bold fs-6">
                      {facialAestheticsContactSection?.subHeading}
                    </h2>
                    <h3 className=" text-center text-lg-start display-4 pb-4">
                      <SafeHtmlParser htmlContent={facialAestheticsContactSection?.heading} />
                    </h3>

                    <SafeHtmlParser
                      htmlContent={facialAestheticsContactSection?.content}
                    />
                  </Col>
                  <Col className="" lg={6}>
                    <ContactForm
                      buttonText={
                        facialAestheticsContactSection?.formButtonLabel
                      }
                    />
                  </Col>
                </Row>
              </Container>
            </section>
          )}
        {facialAestheticsAboveFooterImage && (
          <BgImage
            style={{ height: "60vh", backgroundPosition: "top" }}
            className="w-100 "
            image={
              facialAestheticsAboveFooterImage?.node?.localFile.childImageSharp
                .gatsbyImageData
            }
            alt={facialAestheticsAboveFooterImage.node?.altText}
          />
        )}
      </Layout>
    </>
  );
};

export default FacialAestheticsTreatmentsPage;
